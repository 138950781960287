@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');

@font-face {
  font-family: mali;
  src: url('../public/fonts/Mali/Mali-Medium.ttf');
}


.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #e9d49f;
  font-family: mali;
  font-size: 30px;
  overflow: hidden;
  box-sizing: border-box;
}

.background {
  position: absolute;
  bottom: 0;
  padding: 0;
  box-sizing: border-box;
  opacity: 25%;
}

.backgroundStrip {
  height: 100px;
  width: 100vw;
  background-color: #191e35;
}

.background img {
  position: absolute;
  bottom: 40px;
  right: calc(50% + 400px);
  width: 300px;
}

/*--- FORM ---*/
.storyFormOverlay {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: absolute;
  /* background-color: #00000065 */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.storyFormContainer {
  width: 860px;
  height: 600px;
  background: url('../public/img/storyform_frame.png') no-repeat left top;
  background-color: #ffffff;
  padding: 47px;
  filter: drop-shadow(0px 0px 10px #000000);
}

.storyFormCropper {
  height: 100%;
  width: 100%;
  border-radius: 90px;
  overflow: hidden;
}

.storyForm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 50px;
}

.storyFormLogo {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  top: 30px;
}

.storyForm h2 {
  margin-top: -10px;
}

.storyForm button {
  background-color: #00244c;
  font-size: 25px;
}

.storyLoading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.loadingText {
  font-size: 120px;
}

.storyForm p {
  font-size: 20px;
}

.storyForm form {
  width: 550px;
}

.storyForm select, .storyForm input {
  margin-bottom: 30px;
}

.storyForm fieldset {
  margin-bottom: 30px;
}

/*--- BOOK ---*/
.stf__block {
  filter: drop-shadow(0px 0px 10px #00000062);
}

.storyIllustBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  text-align: center;
  background-color: #eeeeee;

  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 5px; 
    background: url('../public/img/loading_icon_grey.png') no-repeat center center;
    background-size: 200px 200px;
    content: '';
    animation: spin 5s linear infinite;
  }
}

.hideSpinner::after {
  display: none;
  content: none;
}

.storyIllust{
  object-fit: cover;
  height: 100%;
}

.storyIllustCaption {
  width: 100%;
  height: auto; 
  font-size: 20px;
  color: #ffffff;
  background-color: #000000b4;
  padding: 20px;
  transition: .5s ease;
  opacity: 0;
}

.storyIllustBox:hover .storyIllustCaption {
  opacity: 1;
}

.imgLoading {
  height: 100px;
  width: 100px;
}

.storyText {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 0px 40px;
  box-sizing: border-box;
  overflow: auto;
  font-size: 25px;
}

.demoPage {
  box-sizing: border-box;
  padding: 20px;
  background-color: #fdfaf7;
  color: #785e3a;
  border: 1px solid #c2b5a3;
  box-shadow: inset 0 0 30px 0 rgba(36, 10, 3, 0.165), -2px 0 5px 2px rgba(0, 0, 0, 0.138);
}


/*-- LOADING ANIMATION --*/
.spinning {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  0%, 5% { transform: rotate(0deg); }
  20%, 30% { transform: rotate(90deg);}
  45%, 55% { transform: rotate(180deg);}
  70%, 80% { transform: rotate(270deg);}
  95%, 100% { transform: rotate(360deg); }
}
